import React from "react";
import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";
import { StaticQuery, graphql } from "gatsby";
import video from "components/cameras/mp4/built-to-last.hevc_1.mp4";
import videoBackup from "components/cameras/mp4/backups/built-to-last.mp4";

export default function DomeCameras() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "Dome cameras",
        title: "Maximize Coverage with Intelligent Cloud Cameras",
        description:
          "Eliminate blind spots and modernize your security management with cloud-based dome cameras. With plug-and-play deployment, onboard analytics, and native remote access, it’s easy to secure your spaces with confidence. ",
        image: data.headerImage,
        button1: {
          text: "Compare Cameras",
          path: "/cameras/compare-cameras/",
        },
      },
      cardSection: {
        title: "Explore Dome Security Cameras",
        cards: [
          {
            image: data.device1,
            name: "R120",
            description:
              "2MP Dome with Digital PTZ ideal for maximizing camera coverage",
            link: "/cameras/dome-cameras/r120/",
            flex: 3,
          },
          {
            image: data.device2,
            name: "R170",
            description:
              "5MP Micro-Dome with Digital PTZ ideal for discreet indoor deployments",
            link: "/cameras/dome-cameras/r170/",

            flex: 3,
          },
          {
            image: data.device3,
            name: "R200",
            description:
              "5MP Dome with Digital PTZ ideal for powerful and balanced visibility",
            link: "/cameras/dome-cameras/r200/",

            flex: 3,
          },
          {
            image: data.device4,
            name: "R230",
            description:
              "5MP Dome with Optical Zoom ideal for long-distance visibility and WiFi connectivity",
            link: "/cameras/dome-cameras/r230/",

            flex: 3,
          },
          {
            image: data.device5,
            name: "R400",
            description:
              "8MP Dome with Optical Zoom ideal for powerful analytics and long-distance visibility.",
            link: "/cameras/dome-cameras/r400/",

            flex: 3,
          },
        ],
      },
      exploreSection: {
        cards: [
          {
            image: data.recommend1,
            name: "Fisheye Cameras",
            description:
              "Immersive coverage and visibility with minimal camera placement",
            link: "/cameras/fisheye-cameras/",
            flex: 3,
          },
          {
            image: data.recommend2,
            name: "Bullet Cameras",
            description: "Ruggedized form-factor with high-resolution video",
            link: "/cameras/bullet-cameras/",
            flex: 3,
          },
          {
            image: data.recommend3,
            name: "Multisensor Cameras",
            description: "One powerful camera for complete coverage",
            link: "/cameras/multisensor-cameras/",
            flex: 3,
          },
        ],
      },
      benefitsSection: {
        title: "Get Enterprise Performance with Plug-and-Play Technology",
        items: [
          {
            image: data.benefit1,
            title: "Maximize Coverage & Eliminate Blind Spots",
            description:
              "Improve visibility across your entire organization with all-in-one cameras. From entry-point domes to specialized models, dome cameras deliver reliable and comprehensive visibility.",
          },
          {
            image: data.benefit2,
            title: "Powerful Onboard Analytics",
            description:
              "Get crystal-clear footage and lightning-fast processing right out of the box. Footage is analyzed directly on the camera to save time, optimize bandwidth, and accelerate live alerts. ",
          },
          {
            image: data.benefit3,
            title: "Flexible Security In Any Environment",
            description:
              "Indoor or outdoor, PoE or wireless—cloud-based dome cameras are easy to deploy and scale. Modernize your security with a sleek camera that suits any environment.",
          },
        ],
      },
      specSection: {
        title: "Gain Greater Visibility",
        image: data.specImage,
        specs: [
          { icon: data.icon1, title: "10-Year", subTitle: "Warranty" },
          {
            icon: data.icon2,
            title: "Plug-and-Play",
            subTitle: "Simple Setup",
          },
          {
            icon: data.icon3,
            title: "Automatic Updates",
            subTitle: "Included",
          },
          {
            icon: data.icon4,
            title: "Onboard Analytics",
            subTitle: "Included",
          },
          {
            icon: data.icon5,
            title: "Customizable",
            subTitle: "Settings & Permissions",
          },
          {
            icon: data.icon6,
            title: "Secure by Default",
            subTitle: "Framework",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus Dome Cameras - Enterprise Video Surveillance</title>
          <meta
            name="description"
            content="Learn about Rhombus' dome security cameras for commercial businesses, education, healthcare, real estate, and more."
          />
        </Helmet>
        <DevicePageTemplate
          data={pageData}
          video={video}
          videoBackup={videoBackup}
          deviceType="Camera"
          recommend
          reverse
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device3: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device4: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device5: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/r400-outdoor-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/r400-outdoor-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/r400-outdoor-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/spec-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: { eq: "components/common/icons/certification.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(relativePath: { eq: "components/common/icons/plug.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(relativePath: { eq: "components/common/icons/updates.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/common/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(
        relativePath: { eq: "components/common/icons/customizable.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(relativePath: { eq: "components/common/icons/secure.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImageMobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/fisheye-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/bullet-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
